const header = document.querySelector(".header");
const navToggle = document.querySelector(".header__toggle");
const nav = document.querySelector(".nav");
const navLimit = document.querySelector("#navLimit");
const navAfter = document.querySelector("#navAfter");

// Handle nav expanding for mobile sizes
function toggleNav() {
  navToggle.children[0].classList.toggle("header__toggleIcon--close");
  nav.classList.toggle("nav--expanded");
  header.classList.toggle("header--expanded");
}

navToggle.addEventListener("click", function() {
  toggleNav();
});

// Handle delayed nav stickiness
function navObserve(entries, observer) {
  entries.forEach(entry => {
    if (entry.target === navLimit) {
      if (entry.isIntersecting) {
        header.classList.remove("header--followPrep");
      } else {
        header.classList.add("header--followPrep");
      }
    }
    if (entry.target === navAfter) {
      if (entry.intersectionRatio <= 0.2 && entry.boundingClientRect.top < 0) {
        header.classList.add("header--follow");
      } else {
        header.classList.remove("header--follow");
      }
    }
  });
}

const navControllerObserver = new IntersectionObserver(navObserve, {
  threshold: [0.2],
});
navControllerObserver.observe(navLimit);
navControllerObserver.observe(navAfter);

// Billing radio changes
const billRadios = document.querySelectorAll('input[name="payment-plan"]');
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function setBillingPrices(type) {
  const billCosts = document.querySelectorAll(".bill__cost");

  billCosts.forEach(cost => {
    if (type in cost.dataset) {
      const suffix = type === "monthly" ? "month" : "year";
      cost.innerHTML = `${usdFormatter.format(cost.dataset[type])}/${suffix}`;
    }
  });
}

function setBillingLinks(type) {}

function updateBilling(e) {
  setBillingPrices(e.target.value);
  setBillingLinks(e.target.value);
}

billRadios.forEach(radio => {
  radio.addEventListener("change", updateBilling);
});

// smooth scroll all anchors
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener("click", function(e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});
